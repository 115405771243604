import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import FlowChart from "./components/FlowChart";
import Study from "./components/StudyDrive";
import MyNotes from "./components/MyNotes";
import TechniqueDriller from "./components/TechniqueDriller";
import Models from "./components/Models";
import Glossary from "./components/Glossary";
import TechniqueEditor from "./components/TechniqueEditor";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import "./App.css";

const PasswordProtectedStudy = () => {
  const [password, setPassword] = useState("");
  const [accessGranted, setAccessGranted] = useState(false);
  const correctPassword = "oddballs";

  // Check localStorage for the login flag when the component mounts
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isAuthenticated");
    if (isLoggedIn) {
      setAccessGranted(true);
    }
  }, []);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = () => {
    if (password === correctPassword) {
      setAccessGranted(true);
      // Store login status in localStorage
      localStorage.setItem("isAuthenticated", "true");
    } else {
      alert("Incorrect password");
    }
  };

  // const handleLogout = () => {
  //   setAccessGranted(false);
  //   localStorage.removeItem("isAuthenticated");
  // };

  if (accessGranted) {
    return (
      <div>
        <Study />{" "}
        {/* Render the Study component after the password is verified */}
        {/* <Button variant="contained" color="secondary" onClick={handleLogout}>
          Log Out
        </Button> */}
      </div>
    );
  }

  return (
    <div style={{ marginTop: "50px", textAlign: "center" }}>
      <Typography variant="h5">Enter Password</Typography>
      <div>(What's the name of our group?)</div>
      <input
        type="password"
        label="Password"
        variant="outlined"
        value={password}
        onChange={handlePasswordChange}
        className="password-input"
      />
      <br />
      <Button
        onClick={handleSubmit}
        className="submit-button" // Apply custom styles using CSS class
      >
        Submit
      </Button>
    </div>
  );
};

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <div className="content">
          <Routes>
            <Route path="/" element={<TechniqueDriller />} />
            <Route path="/edit" element={<TechniqueEditor />} />
            <Route path="/concepts" element={<Models />} />
            <Route path="/glossary" element={<Glossary />} />
            <Route path="/flow" element={<FlowChart />} />
            {/* Password protected route for Study */}
            <Route path="/study" element={<PasswordProtectedStudy />} />
            <Route path="/notes" element={<MyNotes />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
