import React, { useState, useEffect } from "react";
import VideoEmbed from "./VideoEmbed";
import MyNotes from "./MyNotes";

function Study() {
  const [data, setData] = useState([]); // Store aggregated fetched data
  const [creators, setCreators] = useState([]); // Store unique creators
  const [topics, setTopics] = useState([]); // Store unique topics
  const [selectedCreator, setSelectedCreator] = useState(null); // Store selected creator
  const [selectedTopic, setSelectedTopic] = useState(null); // Store selected topic
  const [filteredLessons, setFilteredLessons] = useState([]); // Store lessons for selected topic
  const [selectedLesson, setSelectedLesson] = useState(null); // Store selected lesson
  const [selectedPart, setSelectedPart] = useState(null); // Store selected part for multi-part lessons
  const [editingNotes, setEditingNotes] = useState(false); // Toggle editing notes

  // Fetch the list of JSON files from study.json and then fetch their contents
  useEffect(() => {
    const fetchStudyFiles = async () => {
      try {
        // Fetch the study.json file, which contains the array of filenames without .json extensions
        const response = await fetch("/study.json");
        const fileNames = await response.json(); // Array of filenames like ["closed-gard-gff", "closed-guard-new-wave"]

        console.log("Fetched file names: ", fileNames);

        // Fetch each JSON file listed in study.json, appending .json to each filename
        const fetchPromises = fileNames.map((file) =>
          fetch(`/study-files/${file}.json`) // Now pointing to the /study-files folder
            .then((res) => {
              if (!res.ok) {
                throw new Error(`Failed to fetch ${file}.json`);
              }
              return res.json();
            })
            .catch((err) => {
              console.error(`Error fetching ${file}.json:`, err);
              return []; // Return an empty array if fetch fails
            })
        );

        // Wait for all fetch promises to resolve
        const fetchedDataArray = await Promise.all(fetchPromises);

        // Combine all fetched JSON data into one array
        const aggregatedData = fetchedDataArray.flat(); // Flattens arrays into one

        console.log("Aggregated Data: ", aggregatedData); // Log the aggregated data

        // Set the aggregated data into the state
        setData(aggregatedData);
      } catch (error) {
        console.error("Error loading data:", error);
        setData([]); // Ensure data is always an array even if fetch fails
      }
    };

    fetchStudyFiles(); // Call the fetch function on component mount
  }, []);

  // Extract unique creators after data is fetched
  useEffect(() => {
    if (data.length > 0) {
      const uniqueCreators = Array.from(
        new Set(data.map((video) => video.creator))
      );
      setCreators(uniqueCreators);
    }
  }, [data]);

  // Extract unique topics based on the selected creator
  useEffect(() => {
    if (data.length > 0) {
      const uniqueTopics = Array.from(
        new Set(
          data
            .filter(
              (video) => !selectedCreator || video.creator === selectedCreator
            )
            .map((video) => video.topic)
        )
      );
      setTopics(uniqueTopics);
    }
  }, [data, selectedCreator]);

  // Handle creator selection
  const handleCreatorChange = (creator) => {
    setSelectedCreator(creator === selectedCreator ? null : creator); // Toggle selection
    setSelectedTopic(null);
    setSelectedLesson(null);
    setSelectedPart(null);
    setEditingNotes(false); // Reset the editing state
  };

  // Handle topic selection
  const handleTopicChange = (topic) => {
    setSelectedTopic(topic === selectedTopic ? null : topic); // Toggle selection
    setSelectedLesson(null);
    setSelectedPart(null);
    setEditingNotes(false); // Reset the editing state
  };

  // Handle lesson selection
  const handleLessonChange = (lesson) => {
    setSelectedLesson(lesson === selectedLesson ? null : lesson); // Toggle lesson selection
    setSelectedPart(null);
    setEditingNotes(false); // Reset the editing state
  };

  // Handle part selection for multi-part lessons
  const handlePartChange = (part) => {
    setSelectedPart(part === selectedPart ? null : part); // Toggle part selection
  };

  // Filter lessons based on selected topic and creator
  useEffect(() => {
    if (selectedTopic) {
      const lessons = data
        .filter(
          (video) =>
            video.topic === selectedTopic &&
            (!selectedCreator || video.creator === selectedCreator)
        )
        .map((video) => video.lesson);

      const uniqueLessons = Array.from(new Set(lessons)); // Remove duplicates
      setFilteredLessons(uniqueLessons);
    } else {
      const allLessons = Array.from(new Set(data.map((video) => video.lesson)));
      setFilteredLessons(allLessons);
    }
  }, [selectedTopic, selectedCreator, data]);

  // Filter videos for selected lesson and part
  const filteredVideos = data.filter(
    (video) =>
      selectedLesson &&
      video.lesson === selectedLesson &&
      (!selectedCreator || video.creator === selectedCreator)
  );

  // Find parts for the selected lesson
  const parts = filteredVideos
    .filter((video) => video.part)
    .map((video) => video.part);

  const uniqueParts = Array.from(new Set(parts)); // Remove duplicate parts

  // Automatically select Part 1 if multiple parts exist
  useEffect(() => {
    if (uniqueParts.length > 0 && selectedPart === null) {
      setSelectedPart(1); // Automatically select Part 1 if parts exist
    }
  }, [uniqueParts, selectedPart]);

  // Select the filtered video based on lesson and part
  const selectedVideo =
    filteredVideos.length > 0
      ? filteredVideos.find((video) =>
          selectedPart ? video.part === selectedPart : true
        )
      : null;

  return (
    <>
      <div className="content">
        {/* Row of radio-style checkboxes for creators */}
        <div className="creators">
          <h3>Creators</h3>
          <div className="checkbox-group">
            {creators.length > 0 ? (
              creators.map((creator, index) => (
                <div key={index} className="checkbox-button">
                  <input
                    type="checkbox"
                    id={`creator-${creator}`}
                    value={creator}
                    checked={selectedCreator === creator}
                    onChange={() => handleCreatorChange(creator)}
                  />
                  <label htmlFor={`creator-${creator}`}>{creator}</label>
                </div>
              ))
            ) : (
              <p>Loading creators...</p>
            )}
          </div>
        </div>

        {/* Row of radio-style checkboxes for topics */}
        <div className="topics">
          <h3>Topics</h3>
          <div className="checkbox-group">
            {topics.length > 0 ? (
              topics.map((topic, index) => (
                <div key={index} className="checkbox-button">
                  <input
                    type="checkbox"
                    id={`topic-${topic}`}
                    value={topic}
                    checked={selectedTopic === topic}
                    onChange={() => handleTopicChange(topic)}
                  />
                  <label htmlFor={`topic-${topic}`}>{topic}</label>
                </div>
              ))
            ) : (
              <p>Loading topics...</p>
            )}
          </div>
        </div>

        {/* Display lesson buttons for selected topic */}
        {filteredLessons.length > 0 && selectedTopic !== null && (
          <div className="lessons">
            <h3>Lessons</h3>
            <div className="checkbox-group">
              {filteredLessons.map((lesson, index) => (
                <div key={index} className="checkbox-button">
                  <input
                    type="checkbox"
                    id={`lesson-${lesson}`}
                    value={lesson}
                    checked={selectedLesson === lesson}
                    onChange={() => handleLessonChange(lesson)}
                  />
                  <label htmlFor={`lesson-${lesson}`}>{lesson}</label>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Display part buttons for multi-part lessons */}
        {uniqueParts.length > 0 && (
          <div className="parts">
            <div className="checkbox-group">
              {uniqueParts.map((part, index) => (
                <div key={index} className="checkbox-button">
                  <input
                    type="checkbox"
                    id={`part-${part}`}
                    value={part}
                    checked={selectedPart === part}
                    onChange={() => handlePartChange(part)}
                  />
                  <label htmlFor={`part-${part}`}>Part {part}</label>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Conditionally render the MyNotes component when editing */}
        {editingNotes && selectedVideo ? (
          <MyNotes lesson={selectedVideo} allData={data} setAllData={setData} />
        ) : (
          <>
            {/* Display video embeds for selected lesson and part */}
            {selectedVideo ? (
              <div className="selected-technique">
                <h3>{selectedLesson}</h3>
                {selectedVideo.creator + " > " + selectedVideo.topic}

                {selectedVideo.part && (
                  <p className="breadcrumb">Part {selectedVideo.part}</p>
                )}
                <VideoEmbed
                  videoId={
                    selectedVideo.url.includes("youtube.com")
                      ? selectedVideo.url.split("v=")[1]
                      : null
                  }
                  googleDriveUrl={
                    selectedVideo.url.includes("drive.google.com")
                      ? selectedVideo.url
                      : null
                  }
                />

                {/* Display chapters and notes */}
                {selectedVideo.chapters &&
                  selectedVideo.chapters.length > 0 && (
                    <div className="chapters" style={{ marginTop: "20px" }}>
                      <h4>Notes</h4>
                      {selectedVideo.chapters.map((chapter, chapterIndex) => (
                        <div key={chapterIndex}>
                          <strong>
                            {selectedVideo.chapters.length > 1 &&
                              chapter.title + " - " + chapter.time}
                          </strong>
                          <ul>
                            {chapter.notes &&
                              chapter.notes.map((note, noteIndex) => (
                                <li key={noteIndex}>{note}</li>
                              ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  )}
              </div>
            ) : (
              <div className="holding-message">
                Select a lesson to view videos
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default Study;
