import React, { useState, useEffect } from "react";

const Glossary = () => {
  const [terms, setTerms] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    fetch("/glossary.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch glossary data");
        }
        return response.json();
      })
      .then((data) => {
        setTerms(data);
        setLoading(false); // Stop loading when data is loaded
      })
      .catch((error) => {
        console.error("Error fetching glossary data:", error);
        setError("Failed to load glossary data."); // Show error message if fetch fails
        setLoading(false); // Stop loading
      });
  }, []);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filter terms based on the search input (using "term" instead of "name")
  const filteredTerms = terms.filter(
    (term) => term.term?.toLowerCase().includes(searchTerm.toLowerCase()) // Filtering by "term"
  );

  // Sort the terms alphabetically by term and group them by "type"
  const sortedTerms = filteredTerms.reduce((acc, term) => {
    const { type } = term;

    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push(term);
    return acc;
  }, {});

  // Sort each type group alphabetically by term
  Object.keys(sortedTerms).forEach((type) => {
    sortedTerms[type].sort((a, b) => a.term.localeCompare(b.term));
  });

  if (loading) {
    return <div>Loading glossary...</div>; // Show loading state
  }

  if (error) {
    return <div>{error}</div>; // Display error message
  }

  return (
    <div className="glossary">
      <input
        className="search-input"
        type="text"
        placeholder="Search"
        value={searchTerm}
        onChange={handleChange}
      />
      {Object.keys(sortedTerms).length > 0 ? (
        Object.keys(sortedTerms).map((type, typeIndex) => (
          <div key={typeIndex}>
            <h3>{type}</h3>
            <ul className="glossary-list">
              {sortedTerms[type].map((term, index) => (
                <li key={index}>
                  <h4 className="glossary-term">{term.term}</h4>
                  <p className="glossary-definition">{term.definition}</p>

                  <p className="glossary-notes">{term.notes}</p>
                </li>
              ))}
            </ul>
          </div>
        ))
      ) : (
        <p>No terms found</p>
      )}
    </div>
  );
};

export default Glossary;
